import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import Register from './components/Register';
import Login from './components/Login';
import Main2 from './components/Main2';  // 로그인 후 보여줄 페이지

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // 로그인 상태 체크 (예: localStorage에서 토큰 확인)
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);  // 토큰이 있으면 로그인된 상태로 처리
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={isLoggedIn ? <Main2 /> : <Main />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
      </Routes>
    </Router>
  );
};

export default App;
