import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
  const [email, setEmail] = useState('');  // 이메일 상태로 변경
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://15.165.74.244/login', {
        email: email,  // 이메일을 서버로 전송
        password: password
      });

      setMessage(response.data);  // 서버 응답 메시지 출력
      setMessage('로그인에 성공하셨습니다 !!')
      setEmail('');  // 로그인 후 이메일 초기화
      setPassword('');  // 로그인 후 비밀번호 초기화
    } catch (error) {
      setMessage('로그인에 실패했습니다.');
      console.error(error);
    }
  };

  return (
    <div>
      <h2>로그인</h2>
      <form onSubmit={handleLogin}>
        <input 
          type="email"  
          placeholder="학교 이메일" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}  // 이메일 상태로 업데이트
          required 
        />
        <input 
          type="password" 
          placeholder="비밀번호" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
        <button type="submit">로그인</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Login;
