import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
  const [nickname, setNickname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [nicknameCheck, setNicknameCheck] = useState(''); // 닉네임 중복 검사 상태

  const handleSendVerificationEmail = async () => {
    try {
      setMessage('');
      const response = await axios.post('http://15.165.74.244/email-send', { email });
      setMessage(response.data.message || '이메일 인증 요청이 성공했습니다.');
    } catch (error) {
      console.error('이메일 인증 요청 실패', error);
      setMessage('이메일 인증 요청에 실패했습니다.');
    }
  };

  const handleVerifyEmail = async () => {
    try {
      setMessage('');
      const response = await axios.post('http://15.165.74.244/email-certification', {
        email,
        univName: "남서울대학교",
        code: parseInt(code, 10)
      });

      const data = response.data;

      if (data.success) {
        setMessage('이메일 인증 코드 확인이 성공했습니다.');
        setIsEmailVerified(true);
      } else {
        setMessage(`이메일 인증 코드 검증 실패: ${data.message}`);
        setIsEmailVerified(false);
      }
    } catch (error) {
      console.error('이메일 인증 코드 검증 실패', error);
      setMessage('이메일 인증 코드 검증에 실패했습니다.');
      setIsEmailVerified(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!isEmailVerified) {
      setMessage('이메일 인증을 완료해 주세요.');
      return;
    }

    setIsSubmitting(true);

    try {
      setMessage('');
      const response = await axios.post('http://15.165.74.244/register', {
        nickname,
        username,
        password,
        email,
        code: parseInt(code, 10), // 안증 코드 정수로 변환 
        emailVerified: isEmailVerified // 이메일 인증 상태 전송 (true or false)
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setMessage(response.data.message || '회원가입이 완료되었습니다.');
      setNickname('');
      setUsername('');
      setPassword('');
      setConfirmPassword('');
      setEmail('');
      setCode('');
      setIsEmailVerified(false); // 회원가입 후 false 처리 
    } catch (error) {
      setMessage('회원가입에 실패했습니다.');
      if (error.response && error.response.data) {
        setMessage(error.response.data.message || '회원가입 실패');
      }
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClearVerifiedUsers = async () => {
    try {
      setMessage('');
      const response = await axios.post('http://15.165.74.244/clear-verified-users');
      setMessage(response.data.message || '인증된 유저 목록 초기화가 성공했습니다.');
    } catch (error) {
      console.error('인증된 유저 목록 초기화 실패', error);
      setMessage('인증된 유저 목록 초기화에 실패했습니다.');
    }
  };

  const checkNickname = async () => {
    try {
      setMessage('');
      const response = await axios.get('http://15.165.74.244/check-nickname', {
        params: { nickname }
      });

      const isDuplicate = response.data.isDuplicate;
      setNicknameCheck(isDuplicate ? '중복된 닉네임입니다.' : '사용 가능한 닉네임입니다.');
    } catch (error) {
      console.error('닉네임 중복 검사 실패', error);
      setNicknameCheck('닉네임 중복 검사 실패');
    }
  };

  return (
    <div>
      <h2>회원가입</h2>
      <form onSubmit={handleRegister}>
        <input 
          type="text" 
          placeholder="닉네임" 
          value={nickname} 
          onChange={(e) => setNickname(e.target.value)} 
          required 
        />
        <button type="button" onClick={checkNickname}>닉네임 중복 검사</button>
        <br />
        <span>{nicknameCheck}</span>
        <br />
        <input 
          type="text" 
          placeholder="실명" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          required 
        />
        <br />
        <input 
          type="password" 
          placeholder="비밀번호" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
        <br />
        <input 
          type="password" 
          placeholder="비밀번호 확인" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          required 
        />
        <br />
        <input 
          type="email" 
          placeholder="이메일" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <button type="button" onClick={handleSendVerificationEmail}>이메일 인증 요청</button>
        <br />
        <input 
          type="text" 
          placeholder="인증 코드" 
          value={code} 
          onChange={(e) => setCode(e.target.value)} 
          required 
        />
        <button type="button" onClick={handleVerifyEmail}>인증 코드 확인</button>
        <br />
        <button type="submit" disabled={isSubmitting}>회원가입</button>
      </form>
      <br />
      <button type="button" onClick={handleClearVerifiedUsers}>인증된 유저 목록 초기화</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Register;
