import React from 'react';
import { useNavigate } from 'react-router-dom';  // useNavigate 훅을 임포트

const Main2 = () => {
  const navigate = useNavigate();  // useNavigate 훅을 사용하여 navigate 함수 얻기

  const handleLogout = () => {
    localStorage.removeItem('token');  // 토큰 삭제 (로그아웃 처리)
    navigate('/login');  // 로그인 페이지로 리디렉션
  };

  return (
    <div>
      <header style={styles.header}>
        <h1>로그인 후 화면</h1>
      </header>

      <section style={styles.section}>
        <div style={styles.content}>
          <h2>환영합니다, 사용자님!</h2>
          <p>이 페이지는 로그인 후 보여지는 내용입니다.</p>
          <button onClick={handleLogout}>로그아웃</button>
        </div>
      </section>

      <footer style={styles.footer}>
        © 2024 REVERSE DataBase 반. 모든 권리 보유.
      </footer>
    </div>
  );
};

const styles = {
  header: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
  },
  section: {
    margin: '20px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    fontSize: '18px',
    lineHeight: '1.6',
  },
  footer: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    position: 'fixed',
    bottom: '0',
    width: '100%',
  },
};

export default Main2;
