import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Main = () => {

  // 입장하기 버튼 클릭 시 토큰을 포함한 요청을 보냄
  const handleEnter = async () => {
    const token = localStorage.getItem('jwtToken'); // 로컬스토리지에서 JWT 토큰 가져오기

    if (!token) {
      alert('로그인 상태가 아닙니다. 로그인 후 다시 시도해주세요.');
      return;
    }

    try {
      const response = await axios.post(
        'http://localhost:8080/입장하기엔드포인트',  // 실제 엔드포인트로 변경
        {},  // 요청 본문 (필요 시 추가 데이터 넣기)
        {
          headers: {
            Authorization: `Bearer ${token}`,  
          },
        }
      );

      if (response.status === 200) {
        alert('입장 성공!');
        // 입장 후 처리 로직 추가 (예: 페이지 이동)
      }
    } catch (error) {
      console.error('입장하기 요청 오류:', error);
      alert('입장에 실패했습니다.');
    }
  };

  return (
    <div>
      <header style={styles.header}>
        <h1>REVERSE DataBase 반에 오신걸 환영합니다 !!</h1>
      </header>

      <section style={styles.section}>
        <div style={styles.content}>
          <h2>DB란 무엇인가요?</h2>
          <p>
            데이터베이스(Database)는 체계적으로 정리된 데이터의 집합입니다. 데이터베이스는 효율적인 데이터 검색, 추가, 삭제, 업데이트 등을 가능하게 하며, 주로 관리 시스템(DBMS)을 통해 조작됩니다.
            <br /> 데이터베이스는 다양한 종류가 있으며, 그 예로는 관계형 데이터베이스, NoSQL 데이터베이스, 클라우드 데이터베이스 등이 있습니다.
          </p>
        </div>

        <div style={styles.buttons}>
          <Link to="/login" style={styles.button}>로그인</Link>
          <Link to="/register" style={styles.button}>회원가입</Link>
          <button onClick={handleEnter} style={styles.button}>입장하기</button> {/* 입장하기 버튼 추가 */}
        </div>

        <div style={styles.team}>
          <h2>우리 팀 구성원</h2>
          <div style={styles.members}>
            <div style={styles.teamMember}>
              <h4>멘토</h4>
              <p>김성철</p>
            </div>
            <div style={styles.teamMember}>
              <h4>멘토</h4>
              <p>함동균</p>
            </div>
            <div style={styles.teamMember}>
              <h4>멘티</h4>
              <p>최임배</p>
            </div>
            <div style={styles.teamMember}>
              <h4>멘티</h4>
              <p>천민기</p>
            </div>
            <div style={styles.teamMember}>
              <h4>멘티</h4>
              <p>박시연</p>
            </div>
          </div>
        </div>
      </section>

      <footer style={styles.footer}>
        © 2024 REVERSE DataBase 반. 모든 권리 보유.
      </footer>
    </div>
  );
};

const styles = {
  header: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
  },
  section: {
    margin: '20px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  content: {
    fontSize: '18px',
    lineHeight: '1.6',
  },
  buttons: {
    marginTop: '20px',
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  button: {
    textDecoration: 'none',
    color: 'white',
    backgroundColor: '#4CAF50',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    textAlign: 'center',
  },
  team: {
    marginTop: '30px',
    textAlign: 'center',
  },
  members: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '20px',
  },
  teamMember: {
    backgroundColor: '#e7f4e4',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '150px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  },
  footer: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    position: 'fixed',
    bottom: '0',
    width: '100%',
  },
};

export default Main;
